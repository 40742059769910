// Import site utilities.
@import '../../global/utils/init';

.l-header {

  .l-header-inner {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 1280px;
    margin: 0 auto;
  }
}

@include md {

  .l-header {

    .l-header-inner {
      flex-direction: column;

      .block-system-header {
        order: 1;
        margin-bottom: 5px;
      }

      .block-system-user-menu {
        order: 2;
        margin-bottom: 10px;
      }

      .block-system-main-menu {
        order: 3;
        align-self: center;

        .menu-toggles-processed {
          background: #d6e2f9;
          left: 0;
          min-width: 250px;
          position: absolute;
          top: 100%;
          z-index: 10;
        }
      }
    }

    .menu-toggle-button-text {
      display: none;
    }
  }

  .header-logo-wrapper {
    max-width: 160px;
    max-height: unset;
    margin: 15px 0 10px;
    margin-left: -5px;

    img {
      max-width: 160px !important;
    }
  }
}
