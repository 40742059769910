// Import site utilities.
@import '../../global/utils/init';

.event-title {
  font-size: 120%;
  font-weight: 900;

  a {

    &:hover {
      text-decoration: none;
      color: #890113;
    }
  }
}

.front {

  .l-content,
  .event-title {

    a {
      color: #1170ec !important;
    }
  }

  .event-title {

    a:hover {
      color: #890113 !important;
    }
  }
}

.view-events {

  .event-title {

    a {
      color: #1170ec !important;
    }
  }
}

.node-type-event .field-name-field-detailed-title,
.detailed-title {
  font-size: 170%;
  font-weight: 900;
  line-height: 1.2;
}


.form-type-uc-quantity label {
  display: none;
}

.form-type-uc-quantity input {
  width: 40px;
}

.node-type-event .uc-product-add-to-cart-form .form-actions {
  width: 40px;
  position: relative;
  top: -75px;
  left: 50px;
}

.node-type-event .uc-product-add-to-cart-form .form-actions .node-add-to-cart {
  font-size: 16px;
  padding: 5px 10px;
}

.view-product-list .views-field {
  vertical-align: baseline;
}

.view-product-list td .uc-product-add-to-cart-form {
  max-height: 50px;
}

.view-product-list .views-field-addtocartlink-1 {
  padding-right: 160px;
}

.view-product-list table {
  overflow: visible;
}

.node-type-event .field-name-field-detailed-title {
  font-size: 2rem;
}

.node-type-event .field-name-body {
  margin-bottom: 50px;
}


@include md {
    
  .view-product-list .views-field-title,
  .view-product-list .views-field-sell-price {
    font-size: 15px;
    max-width: 100%;
  }
  
  .view-product-list .views-field-addtocartlink-1 {
    padding-right: 0;
    width: 100%;
  }

  .view-product-list .uc-product-add-to-cart-form {
    max-height: unset;
  }
  
  .view-product-list .form-item-qty {
    margin-bottom: 10px;
    padding-top: 5px;
  }
  
  .view-product-list .form-type-uc-quantity input {
    font-size: 16px;
    padding: 2px;
    text-align: center;
    width: 30px;
  }
  
  .node-type-event .uc-product-add-to-cart-form .form-actions {
    top: -45px;
    left: 54px;
  }
  
  .view-product-list .uc-product-add-to-cart-form .node-add-to-cart {
    font-size: 10px;
    margin-right: 0 !important;
    padding: 6px !important;
    border-radius: 5px !important;
    font-weight: 600;
  }

  .node-type-event .uc-product-add-to-cart-form .form-actions .node-add-to-cart {
    font-size: 10px;
    padding: 5px !important;
  }
  
  .evenet-wrapper {
    flex-direction: column-reverse;
  }
  
  .event-detail-wrapper {
    flex-basis: 100%;
  }
  
  .evenet-wrapper .event-title {
    font-size: 1.3rem;
    margin-bottom: 10px;
    line-height: 24px;
  }
  
  .evenet-wrapper .detailed-title {
    font-size: 1.6rem;
  }
}

// input#edit-submit-1743 {
//   left: 200px;
//   position: relative;
//   top: -10px;
// }

.uc-cart-view-form .form-actions a {
  visibility: hidden;
}

.uc-cart-view-form .form-actions a::after {
  visibility: visible;
  content: 'Make another selection';
  display: block;
}
