// Import site utilities.
@import '../../global/utils/init';

.uc-cart-checkout-form .products a,
.uc-cart-view-form .desc a,
.cart-review .products a {
  text-decoration: none;
  color: #000;
  cursor: auto;
}

#uc-cart-view-form {

  thead tr th:nth-child(3),
  tbody .image {
    display: none;
  }
}

#uc-cart-checkout-form,
.collapsible > .fieldset-wrapper > .fieldset-description {
  padding-left: 0;
}
