// Import site utilities.
@import '../../global/utils/init';

.front {

  .layout-front {

    .block-hero {
      padding: 30px !important;
      min-height: unset !important;
      height: unset !important;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 0;
      }
    }
  }

  .field-name-body {

    h3 {
      font-family: var(--opera-san-font);
      font-size: 34px;
      font-weight: 900;
      line-height: 1.2;
    }
  }

  .block-views-events-block {
    background-color: #d6e2f9 !important;

    .block-title {
      font-family: var(--opera-san-font);
      font-size: 34px;
      font-weight: 900;
      line-height: 1.2;
    }

    .detailed-title {
      font-size: 28px;
    }
  }

  .block-layout-hero {

    .block-content {
      z-index: 10;
      position: relative;
  
      h3 {
        font-size: 26px;
      }
    }
  }
}

@include md {

  .front {

    .layout-front {

      .block-hero {
        padding: 20px !important;
        min-height: unset !important;

        .block-content {
          
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
  
  .block-hero-image {

    .block-content {
      display: block;
    }
  }
}
