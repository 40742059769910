// Fonts generated using:
// https://www.fontsquirrel.com/tools/webfont-generator
//
// For more information on font-face and browser support visit:
// https://css-tricks.com/snippets/css/using-font-face/

// Sample font face
// @font-face {
//   font-family: 'FontName';
//   font-style: normal;
//   font-weight: 700;
//   // The browser will hide the text for about 100ms and, if the font has not yet
//   // been downloaded, will use the fallback text. It will swap to the new font
//   // after it is downloaded, but only during a short swap period
//   // https://css-tricks.com/almanac/properties/f/font-display/
//   font-display: fallback;
//   src:
//     url('../fonts/font-name.woff2') format('woff2'),
//     url('../fonts/font-name-lato-bold.woff') format('woff');
// }

// Avenir
@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  src: url('../fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: url('../fonts/AvenirLTStd-Roman.otf');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  src: url('../fonts/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 600;
  src: url('../fonts/Avenir-Heavy.ttf');
}

//Archer font
@font-face {
  font-family: 'Archer';
  font-weight: 100;
  src: url('../fonts/ArcherHairlinePro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 200;
  src: url('../fonts/ArcherXLightPro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 300;
  src:
    url('../fonts/ArcherLightPro.otf'),
    url('../fonts/ArcherLightItalPro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 400;
  src:
    url('../fonts/ArcherBookPro.otf'),
    url('../fonts/ArcherBookItalPro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 500;
  src: url('../fonts/ArcherMediumPro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 600;
  src: url('../fonts/ArcherSemiboldPro.otf');
}

@font-face {
  font-family: 'Archer';
  font-weight: 900;
  src: url('../fonts/ArcherBoldPro.otf');
}

@font-face {
  font-family: 'Signature';
  src: url('../fonts/GreatVibes-Regular.ttf');
}
