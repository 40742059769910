// Import site utilities.
@import '../../global/utils/init';

.l-header-inner {
  min-height: 135px;
}

.l-header .menu a {
  padding: 15px;
}

.block-system-header .block-content .header-menu {
  align-self: center;
}

.menu-dropdown a span.sub-arrow {
  margin-top: 0;
}

.header-logo-wrapper img,
.header-logo-wrapper {
  max-height: max-content;
  width: auto;
  max-width: 250px;
  height: auto;
}

.menu > li.last {
  margin-left: 10px;
}

.block-system-main-menu {
  margin-left: auto;
}

.block-system-user-menu .menu > li.last a {
  padding: 10px 20px !important;
  border-radius: 5px;
  background-color: #add8e6;
  color: var(--color-black);
}

.block-system-user-menu .menu > li.last a:hover {
  border-radius: 5px;
  background-color: #9ea5de;
  text-decoration: underline;
}

.l-header .menu-dropdown {
  display: flex;
  align-items: center;
}

@media (min-width: 48em) {

  .menu-toggle-state:not(:checked) ~ .menu {
    float: right;
  }
}

@media (min-width: 62rem) {

  .l-header .menu > li {
    font-size: 16px;
  }
}

@include md {

  .menu-dropdown {

    a {

      span {
        
        &.sub-arrow {
          background: none;
          border: none;
          height: 40px;
          margin-top: 0;
          top: 0;
          right: 10px;
          width: auto;

          &::before {
            display: block;
            content: '+';
            margin-top: 0.5625rem;
          }
        }
      }
    }

    ul {
      background: rgba(0, 0, 0, 0.1);
    }
  }
    
  .header-menu {
    padding: 16px 16px 0;
  }

  .l-header .menu a {
    color: var(--color-black);
    font-size: 16px;
  }

  .menu > li.last {
    margin-left: 0;
  }

  .header-menu .links {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .header-menu .links a {
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
  }
    
  .block-system-main-menu {
    margin-bottom: 0;
    margin-left: unset;
  }

  .logged-in {

    .block-system-main-menu {
      position: unset;

      .menu-dropdown {

        li {
  
          &:last-child {
            border-top: 1px solid #bbbbbb;
          }
        }
      }
    }
  }

  .front .l-content .block--inner-wrapper,
  .l-footer .block--inner-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
    
  .front figure {
    float: left;
  }
    
  .column-count-3,
  .column-count-2 {
    column-count: 1;
  }
    
  .node-card ul {
    padding-left: 21px;
  }
    
  .block-block-copyright-block,
  .block-block-copyright-block p {
    margin: 0;
  }
    
  .block-views-donation-view-block {
    margin-top: 30px;
  }
    
  .uc-store-address-field .form-item label {
    text-align: left;
    padding-left: 0 !important;
  }
}
